@import url('https://fonts.googleapis.com/css2?family=Outfit:wght@400;500;700&display=swap');

/* Yleiset responsiiviset asetukset */
@media (min-width: 1024px) {
  .container {
    max-width: 1000px;
  }
}

@media (max-width: 768px) {
  .container {
    padding: 10px;
  }

  form input:focus,
  form textarea:focus {
    outline: 2px solid #6f9a5b37;
  }

  button {
    padding: 8px 10px;
  }
}

@media (max-width: 480px) {
  .container {
    margin: 10px;
  }

  .note-item h2 {
    font-size: 1em;
  }

  button {
    font-size: 14px;
  }
}

/* Väriteemat */
:root {
  --container-bg-color: rgba(255, 255, 255, 0.9);
  --text-color: #333;
  --button-bg-color: #6f9a5b37;
  --button-text-color: #ffffff;
  --note-bg-color: #fefefe;
  --note-border-color: #ddd;
  --shadow-color: rgba(0, 0, 0, 0.15);
  --transition: 0.3s ease-in-out;
  --overlay-color: rgba(255, 255, 255, 0.7);
}

body.dark-mode {
  background-image: url('/src/data/taustakuva_dark.png');
  --container-bg-color: rgba(30, 30, 30, 0.9);
  --text-color: #fff;
  --button-bg-color: #333;
  --button-text-color: #fff;
  --note-bg-color: #2a2a2a;
  --note-border-color: #444;
  --shadow-color: rgba(255, 255, 255, 0.2);
  --overlay-color: rgba(0, 0, 0, 0.7);
}

/* Bodyasettelu */
body {
  font-family: 'Outfit', sans-serif;
  background-image: url('/src/data/taustakuva.png');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-attachment: fixed;
  color: var(--text-color);
  margin: 0;
  padding: 0;
  transition: background-color 0.3s, color 0.3s;
  overflow: auto;
  padding-right: 0;
}

/*
body::before {
  content: '';
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: var(--overlay-color);
  z-index: -1;
}*/

.container {
  max-width: 800px;
  margin: 20px auto;
  background-color: var(--container-bg-color);
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 8px var(--shadow-color);
  transition: background-color 0.3s, box-shadow 0.3s;
}

form input,
form textarea {
  width: 100%;
  padding: 10px;
  margin: 10px 0;
  border-color: #6f9a5b37;
  outline: none;
  box-shadow: 0 0 5px rgba(124, 102, 53, 0.5);
  border-radius: 4px;
  border: 1px solid var(--note-border-color);
  background-color: var(--note-bg-color);
  color: var(--text-color);
  transition: background-color 0.3s, color 0.3s, border-color 0.3s;
}

form input:focus,
form textarea:focus {
  outline: 2px solid var(--button-bg-color);
  outline-offset: 2px;
  box-shadow: 0 0 5px #6f9a5b37;
}

button {
  color:#fff;
  background-color: var(--button-bg-color);
  border-color: #5f8560;
  border: none;
  cursor: pointer;
  padding: 10px 15px;
  border-radius: 4px;
  transition: var(--transition);
  -webkit-tap-highlight-color: transparent;
  
}

button:hover {
  background-color: #6f9a5b37;
  transform: scale(1.05);
} 

button:focus {
  outline: 2px solid var(--button-bg-color);
  outline-offset: 2px;
  box-shadow: 0 0 5px #6f9a5b37;
} 

.logo-button {
  color: transparent;
  background: transparent;  
  border: none;             
  box-shadow: none;        
  cursor: pointer; 
  transition: none; 
  transition-duration: 0ms;   
  transform: none;    
  display: none;
  -webkit-tap-highlight-color: transparent;
}

.logo-button:focus {
  color: transparent;
  outline: none;
  box-shadow: none;
  background: transparent;
}
.logo-button:hover {
  background-color: #ffffff00;
  background: transparent;  
  box-shadow: none;    
}

.logo-button:active {
  color: transparent;
  background: transparent;  
  box-shadow: none;   
  transform: none;
  transition: none;       
}

.note-item {
  border: 1px solid var(--note-border-color);
  margin-bottom: 10px;
  padding: 10px;
  border-radius: 4px;
  background-color: var(--note-bg-color);
  transition: var(--transition);
  box-shadow: 0 2px 6px var(--shadow-color);
}

.note-item h2 {
  margin: 0;
  font-size: 1.2em;
}

.note-item p {
  margin: 5px 0;
  color: var(--secondary-text-color);
  transition: color 0.3s;
}

.note-item button {
  margin-right: 5px;
}

.no-items {
  text-align: center;
  color: var(--secondary-text-color);
  margin-top: 20px;
  transition: color var(--transition);
}

input[type=text] {
  border: 2px solid rgba(7, 54, 22, 0.315);
  border-radius: 4px;
}

body:not(.dark-mode) a:link {
  color: black;
}

body:not(.dark-mode) a:visited {
  color: black;
}

body.dark-mode a:link {
  color: white;
}

body.dark-mode a:visited {
  color: white;
}

.MuiButton-root {
  transition: background-color 0.3s, color 0.3s;
}

body.dark-mode .MuiButton-root {
  color: white;

}

body:not(.dark-mode) .MuiButton-root {
  color: black;
}

.custom-app-bar-light.MuiAppBar-root {
  position: static;
  background-color: #5f8560; 
  color: white;
  
}

.custom-app-bar-dark.MuiAppBar-root {
  position: static;
  background-color: #333; 
  color: white;
}

.footer {
  position: static;
  left: 0;
  bottom: 3;
  width: 100%;
}
